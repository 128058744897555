* {
  box-sizing: border-box;
}

html {
  background: #f0f0f0;
}

body {
  position: relative;
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 14px;
  background: white;
  min-height: 100vh;
}

@media print {
  html {
    background: transparent;
  }
  body {
    font-size: 11px;
  }
  @page {
    margin: 1.4cm;
  }

  .hide-on-print {
    display: none !important;
  }
}

body::after {
  content: " ";
  display: block;
  clear: both;
  height: 1px;
}

textarea {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 1em;
}
h1,
h2,
h3,
h4 {
  font-family: "Open Sans";
  font-weight: 300;
}

[contenteditable="true"]:empty:before {
  content: attr(placeholder);
  display: block; /* For Firefox */
  color: rgba(0, 0, 0, 0.33);
}

a {
  text-decoration: none;
}

.clip-text {
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

tr {
  page-break-inside: avoid;
  page-break-after: auto;
}

/*  Animations  */

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.6;
  }
}

@keyframes flicker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
